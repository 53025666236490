import React from "react";
import "framework7-icons";

interface IiconProps {
  text: string; // pass icon name
  ariaHidden?: boolean
  onClickEvent?: any
}

const Yicon: React.FC<IiconProps> = ({ text, ariaHidden, onClickEvent }) => {
  return <i className="f7-icons no-translate" aria-hidden={ariaHidden} onClick={onClickEvent ? onClickEvent : undefined} >{text}</i>;
};
export default Yicon;
